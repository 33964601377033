import {
  useReducer,
  useEffect
} from 'react';
import { navigate } from 'gatsby'
import _has from 'lodash/has'
import _filter from 'lodash/filter'
import _get from 'lodash/get'
import _isNil from 'lodash/isNil'
import _map from 'lodash/map'
import _pick from 'lodash/pick'
import _flatMap from 'lodash/flatMap'
import FastSpringStoreContext from './FastSpringStore.context';
import { FS_EVENTS, FS_SESSION_KEY } from './FastSpringStore.keys';
import fastSpringStoreReducer, {
  INITIAL_STATE,
  cleanShopStore,
  setOrderInfo
} from './FastSpringStore.module'


const mapFSDataToPayload = (data) => {
  const productGroups = _get(data, 'groups')
  // Flatten product group items into array of all products
  const products = _flatMap(productGroups, (group) => {
    return _map(_get(group, 'items'), item => _pick(item, [
      'pid',
      'priceTotal',
      'total',
      'image',
      'display',
      'selected'
    ]))
  })
  const productsInCart = _map(_filter(products, ['selected', true ]), 'pid')

  return {
    productsInCart,
    products,
    order: _pick(data, [
      'originalTotal',
      'discountTotal',
      'taxType',
      'taxRate',
      'taxPriceType',
      'tax',
      'totalWithTax',
      'allCrossSells',
      'firstCrossSellConfigurationDisplay'
    ]),
  }
}

const resolveInitialState = () => {
  if (typeof window !== `undefined`) {
    const initialData = window.sessionStorage.getItem(FS_SESSION_KEY)

    if (!_isNil(initialData)) {
      return mapFSDataToPayload(JSON.parse(initialData))
    }
  }

  return INITIAL_STATE
};

export default ({ children }) => {
  const [state, dispatch] = useReducer(fastSpringStoreReducer, resolveInitialState())

  useEffect(() => {
    const onPopupClosed = (data) => {
      // Popup was closed and order was finished (we have order id)
      if (_has(data.detail, 'id')) {
        // Navigate to home page
        // TODO: Show thank you page in the future
        navigate('/')
        dispatch(cleanShopStore())
      }
    }

    const onDataUpdate = (data) => {
      dispatch(
        setOrderInfo(
          mapFSDataToPayload(
            data.detail
          )
        )
      )
    }

    // https://developer.fastspring.com/docs/callbacks
    window.addEventListener(FS_EVENTS.POPUP_CLOSE, onPopupClosed, false);
    // https://fastspringexamples.com/callback/data-data-callback/
    window.addEventListener(FS_EVENTS.DATA_UPDATE, onDataUpdate, false);


    return () => {
      window.removeEventListener(FS_EVENTS.POPUP_CLOSE, onPopupClosed)
      window.removeEventListener(FS_EVENTS.DATA_UPDATE, onDataUpdate)
      window.sessionStorage.removeItem(FS_SESSION_KEY)
    }
  }, [])

  return (
    <FastSpringStoreContext.Provider value={[state, dispatch]}>
      {children}
    </FastSpringStoreContext.Provider>
  )
};

