import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faFacebook,
  faYoutube,
  faTwitter
} from '@fortawesome/free-brands-svg-icons'
import BrandsSlider from 'components/BrandsSlider'
import Year from '../../../common/src/components/year'

export default () => (
  <>
    <div id="bottom" className="container-fluid bg-black top-bdr mt-5">
      <div className="row justify-content-center bg-footer-img">
        <div className="col-xl-9 col-lg-10 col-md-12 p-0">
          <div className="row pt-5 pb-5 m-0">

            <Link to="/" className="col-lg-3 col-md-3 logo pr-4">
              <StaticImage
                imgClassName="img-fluid"
                src="../images/full-logo.png"
                alt="air logo"
                placeholder="none"
                height={35}
              />
            </Link>


            <div className="col">
              <h5>PRODUCTS</h5>
              <ul className="text-left pl-0">
                <li><Link to="/virtual-instruments">Virtual Instruments</Link></li>
                <li><Link to="/effects">Effects</Link></li>
                <li><Link to="/expansions">Expansions</Link></li>
                <li><Link to="/sound-packs">Sound Packs</Link></li>
              </ul>
            </div>


            <div className="col">
              <h5>SUPPORT</h5>
              <ul className="text-left pl-0">
                <li><a href="http://support.sonivoxmi.com/support/home" target="_blank">Knowledge Base</a></li>
                <li>
                  <a href="https://support.sonivoxmi.com/en/support/home" target="_blank" rel="noreferrer">
                    Contact Support
                  </a>
                </li>
                <li><Link to='/refundpolicy'>Refund Policy</Link></li>
              </ul>
            </div>


            <div className="col">
              <h5>COMPANY</h5>
              <ul className="text-left pl-0">
                <li><Link to="/about-us.html">About Us</Link></li>
              </ul>
            </div>


            <div className="col">
              <h5>FOLLOW US</h5>

              <ul className="row pl-3">
                <li className="col-auto pt-1 pr-1 pb-1 pl-0">
                  <a href="http://www.facebook.com/pages/SONiVOX-Sound-that-Rocks/111857542229788?sk=wall" target="_blank">
                    <FontAwesomeIcon
                      icon={faFacebook}
                      style={{
                        width: 'auto',
                        height: '28px'
                      }}
                    />
                  </a>
                </li>
                <li className="col-auto p-1">
                  <a href="http://www.twitter.com/sonivox" target="_blank">
                    <FontAwesomeIcon
                      icon={faTwitter}
                      style={{
                        width: 'auto',
                        height: '28px'
                      }}
                    />
                  </a>
                </li>
                <li className="col-auto p-1">
                  <a href="https://www.youtube.com/user/SONiVOXmi?feature=mhum" target="_blank">
                    <FontAwesomeIcon
                      icon={faYoutube}
                      style={{
                        width: 'auto',
                        height: '28px'
                      }}
                    />
                  </a>
                </li>
              </ul>
            </div>
          </div>

        </div>
      </div>
    </div>
    <footer>
      <div className="container-fluid bglight">
        <div className="row justify-content-center align-items-center text-center">
          <div className="col-10 col-xl-8 p-0">

            <div id="inmusic-logos-scroller text-center">
              <div className="inmusic-logos-scroller-title">
                <StaticImage
                  imgClassName="invert"
                  src="../images/brand-logos/logo-inmusic-wht.png"
                  placeholder="blurred"
                  height={20}
                  alt="InMusic logo"
                />
                <p>Home of the world's premier music and audio technology brands</p>
                <hr />
              </div>
              <BrandsSlider />
            </div>

            <br /><br /><br />


            <div className="row text-center m-0">
              <div className="col copyright">
                <p>© <Year /> inMusic Brands Inc. • All Rights Reserved.</p>
                <p>
                  <Link to="/privacy.html" target="_blank">
                    Privacy Policy
                  </Link>
                  &nbsp;·&nbsp;
                  <Link to="/terms-of-use.html" target="_blank">
                    Terms of Use
                  </Link>
                </p>
              </div>
            </div>

          </div>
        </div>
      </div>
    </footer>
  </>
)
