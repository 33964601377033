import * as React from 'react'

const Year = () => {
    const year = new Date().getFullYear()
    return(
        <>{year}</>
    )
}

export default Year
