import _get from 'lodash/get';
import _isNil from 'lodash/isNil';
import _uniq from 'lodash/uniq';
import _without from 'lodash/without';
import _includes from 'lodash/includes';

export const INITIAL_STATE = {
  productsInCart: [],
  products: [],
  order: {}
};

const CLEAN_SHOP_STORE = 'CLEAN_SHOP_STORE';
export const cleanShopStore = () => ({
  type: CLEAN_SHOP_STORE
});

const SET_ORDER_INFO = 'SET_ORDER_INFO';
export const setOrderInfo = (payload) => ({
  type: SET_ORDER_INFO,
  payload
});

const ADD_TO_CART = 'ADD_TO_CART';
export const addToCart = (productId) => ({
  type: ADD_TO_CART,
  payload: { productId }
});

const REMOVE_FROM_CART = 'REMOVE_FROM_CART';
export const removeFromCart = (productId) => ({
  type: REMOVE_FROM_CART,
  payload: { productId }
});

export const isProductInACart = (productId) => (state) => {
  return _includes(state.products, productId);
};


export default (state, action) => {
  const type = _get(action, 'type');
  const productId = _get(action, 'payload.productId');

  switch (type) {
    case CLEAN_SHOP_STORE: {
      return INITIAL_STATE
    }
    case SET_ORDER_INFO: {
      return {
        ...state,
        ..._get(action, 'payload', {})
      }
    }
    case ADD_TO_CART: {
      if (_isNil(productId)) {
        return state
      }

      return {
        ...state,
        order: {},
        productsInCart: _uniq([
          ...state.productsInCart,
          productId,
        ])
      };
    }
    case REMOVE_FROM_CART:{
      if (_isNil(productId)) {
        return state
      }

      return {
        ...state,
        order: {},
        productsInCart: _without(state.productsInCart, productId)
      };
    }
    default:
      throw new Error('Unrecognized action');
  }
};
