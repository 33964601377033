exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-html-js": () => import("./../../../src/pages/about-us.html.js" /* webpackChunkName: "component---src-pages-about-us-html-js" */),
  "component---src-pages-cart-js": () => import("./../../../src/pages/cart.js" /* webpackChunkName: "component---src-pages-cart-js" */),
  "component---src-pages-contact-response-html-js": () => import("./../../../src/pages/contact-response.html.js" /* webpackChunkName: "component---src-pages-contact-response-html-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-html-js": () => import("./../../../src/pages/privacy.html.js" /* webpackChunkName: "component---src-pages-privacy-html-js" */),
  "component---src-pages-refundpolicy-js": () => import("./../../../src/pages/refundpolicy.js" /* webpackChunkName: "component---src-pages-refundpolicy-js" */),
  "component---src-pages-search-js": () => import("./../../../src/pages/search.js" /* webpackChunkName: "component---src-pages-search-js" */),
  "component---src-pages-terms-of-use-html-js": () => import("./../../../src/pages/terms-of-use.html.js" /* webpackChunkName: "component---src-pages-terms-of-use-html-js" */),
  "component---src-pages-virtual-instruments-time-warp-2600-html-js": () => import("./../../../src/pages/virtual-instruments/time-warp-2600.html.js" /* webpackChunkName: "component---src-pages-virtual-instruments-time-warp-2600-html-js" */),
  "component---src-templates-category-js": () => import("./../../../src/templates/category.js" /* webpackChunkName: "component---src-templates-category-js" */),
  "component---src-templates-error-js": () => import("./../../../src/templates/error.js" /* webpackChunkName: "component---src-templates-error-js" */),
  "component---src-templates-product-js": () => import("./../../../src/templates/product.js" /* webpackChunkName: "component---src-templates-product-js" */)
}

