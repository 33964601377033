import { memo } from 'react';
import { Link } from 'gatsby';
import classNames from 'classnames';
import useProductsInCartCount from '../modules/FastSpringStore/useProductsInCartCount';

export default memo(({ productId, children, className, onClick, addedToCartAddon, cartRoute='/cart', ...props }) => {
	const productsInCartCount = useProductsInCartCount()
	return (
		<Link to={cartRoute} className={classNames('btn nav-link position-relative cart', className)} {...props}>
			{children}
			{productsInCartCount > 0 && (
				<span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
					{productsInCartCount}
				</span>
			)}
		</Link>
	)
})
