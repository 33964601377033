import Nav from 'components/Nav'
import Footer from 'components/Footer'
import 'styles/mainStyle.scss'


export default ({ children }) => (
  <>
    <Nav />
    <main>
      {children}
    </main>
    <Footer />
  </>
)
