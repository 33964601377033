import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faUser, faShoppingCart, faSearch } from "@fortawesome/free-solid-svg-icons"
import CartButton from 'common/components/CartButton';
import useStickyNav from 'common/hooks/useStickyNav';
import { useEffect } from "react";

export default () => {
	const { isSticky, navRef, toggleNav, navVisible } = useStickyNav()

	useEffect(() => {
		document.getElementById('cartButton').firstChild.classList.remove('btn')
		document.querySelector('#gatsby-focus-wrapper > div:nth-child(1) > div > div > div > nav > div:nth-child(2) > a.btn.nav-link.position-relative.d-inline.d-md-none.mx-3').classList.remove('btn')
	}, []);

	return (
		<div style={{ minHeight: '64px' }}>
			<div className={`container-fluid navbar-light bg-light ${isSticky ? 'fixed-top' : ''}`} ref={navRef}>
				<div className="row justify-content-center align-items-center d-flex">
					<div className="col-lg-11 col-md-12 p-0 align-items-center">
						<nav className="navbar navbar-expand-md">
							<Link to="/" style={{ maxWidth: '250px' }} className="navbar-brand mr-0 mr-md-5 d-flex">
								<StaticImage src="../images/full-logo.png" alt="logo" placeholder="none" className="mt-1" width={250} quality={100} />
							</Link>

							<div>
								<Link to="/search" className="search d-inline d-md-none nav-link">
									<FontAwesomeIcon icon={faSearch} size="lg" title="Search" />
								</Link>

								<CartButton className="d-inline d-md-none mx-3">
									<FontAwesomeIcon icon={faShoppingCart} size="lg" title="Shopping cart" />
								</CartButton>

								<button className="navbar-toggler p-0" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation" onClick={toggleNav}>
									<span className="navbar-toggler-icon">
										<StaticImage src="../images/toggler.svg" alt="logo" placeholder="blurred" layout="fixed" width={30} height={30} />
									</span>
								</button>
							</div>

							<div className={`collapse navbar-collapse ${navVisible ? 'show' : ''}`}
								id="navbarNav">

								<ul className="navbar-nav align-items-center">
									<li className="nav-item text-center">
										<Link to="/virtual-instruments" className="nav-link" activeClassName="active" >
											Virtual Instruments
										</Link>
									</li>
									<li className="nav-item text-center">
										<Link to="/effects" className="nav-link" activeClassName="active" >
											Effects
										</Link>
									</li>
									<li className="nav-item text-center">
										<Link to="/expansions" className="nav-link" activeClassName="active" >
											Expansions
										</Link>
									</li>
									<li className="nav-item text-center">
										<Link to="/sound-packs" className="nav-link" activeClassName="active" >
											Sound Packs
										</Link>
									</li>
									<li className="nav-item text-center">
										<a href="https://support.sonivoxmi.com/en/support/home" target="_blank" rel="noreferrer" className="nav-link" activeClassName="active"										>
											SUPPORT
										</a>
									</li>
								</ul>

								<ul className="navbar-nav align-items-center ml-auto">
									<li className="nav-item text-center d-none d-md-inline">
										<Link to="/search" className="search nav-link">
											<FontAwesomeIcon icon={faSearch} size="lg" title="Search" />
										</Link>
									</li>
									<li id="cartButton" className="nav-item text-center d-none d-md-inline">
										<CartButton>
											<FontAwesomeIcon icon={faShoppingCart} size="lg" title="Shopping cart" />
										</CartButton>
									</li>
									<li className="nav-item text-center">
										<a className="nav-link" href="https://sonivoxmi.onfastspring.com/account" target="_blank"										>
											<FontAwesomeIcon icon={faUser} size="lg" title="Account" className="d-none d-md-inline" />
											<span className="d-block d-md-none"											>
												Account
											</span>
										</a>
									</li>
								</ul>
							</div>
						</nav>

					</div>
				</div>
			</div>
		</div>
	)
}
